"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Steps", {
  enumerable: true,
  get: function get() {
    return _Steps["default"];
  }
});
Object.defineProperty(exports, "Hints", {
  enumerable: true,
  get: function get() {
    return _Hints["default"];
  }
});

var _Steps = _interopRequireDefault(require("./components/Steps"));

var _Hints = _interopRequireDefault(require("./components/Hints"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }