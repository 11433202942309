"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.options = void 0;

/**
 * Intro.js options default proptypes.
 * @type {Object}
 */
var options = {
  hidePrev: true,
  hideNext: true
};
exports.options = options;